<template>
  <div v-if="applicationData">
    <v-card>
      <v-card-title>
        <div class="mb-2 mb-sm-0 w-full">
          <v-icon @click="$router.back()">{{ icons.mdiArrowLeftBoldCircleOutline }}</v-icon>
          Application View
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12">
            <table class="application-table">
              <tr>
                <td class="application-id">
                  <p class="text-no-wrap text--primary font-weight-medium">ID:</p>
                </td>
                <td>
                  <p class="event-id">{{ applicationData.id }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Name:</p>
                </td>
                <td>
                  <p class="merchant-name">{{ applicationData.name }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Email:</p>
                </td>
                <td>
                  <p class="website-name">{{ applicationData.email }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Website URL:</p>
                </td>
                <td>
                  <p class="service">{{ applicationData.website }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Phone:</p>
                </td>
                <td>
                  <p class="service">{{ applicationData.number }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Messenger:</p>
                </td>
                <td>
                  <p class="service">{{ applicationData.messenger }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Account:</p>
                </td>
                <td>
                  <p class="service">{{ applicationData.account }}</p>
                </td>
              </tr>
              <tr>
                <td class="event-title">
                  <p class="text-no-wrap text--primary font-weight-medium">Created At:</p>
                </td>
                <td>
                  <p class="created-at">
                    {{ applicationData.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
                  </p>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiArrowLeftBoldCircleOutline } from '@mdi/js';
import store from '@/store';
import router from '@/router';
import { ref } from '@vue/composition-api';

export default {
  setup() {
    const applicationData = ref(null);

    store
      .dispatch('application/fetchApplicationById', { id: router.currentRoute.params.id })
      .then(response => {
        applicationData.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          applicationData.value = undefined;
        }
      });

    return {
      applicationData,
      icons: {
        mdiArrowLeftBoldCircleOutline,
      },
    };
  },
};
</script>
